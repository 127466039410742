@import 'shared/mixins';
@import 'shared/breakpoints';
@import 'shared/vw';
@import 'shared/colors';

.ewa-button {
  @include font-ttfirsneue();
  font-size: getVwMobile(14px);
  font-weight: 400;
  height: getVwMobile(42px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: $money-black;
  border: none;
  color: $white;
  padding-right: getVwMobile(32px);
  padding-left: getVwMobile(32px);

  &:hover {
    background: #000;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  @media (min-width: $lg) {
    font-size: getVwDesktop(16px);
    height: getVwDesktop(56px);
    padding-right: getVwDesktop(32px);
    padding-left: getVwDesktop(32px);
  }

  @media (min-width: $desktop) {
    font-size: 16px;
    height: 56px;
    padding-right: 32px;
    padding-left: 32px;
  }
}

.ewa-button.--outlined {
  background: none;
  border: 1px solid $money-black;
  color: $money-black;
}

.ewa-button.--secondary {
  background: $brand-color;
  color: $white;

  &:hover {
    opacity: 0.8;
  }
}

.ewa-button.--large {
  height: unset;
  padding: getVwMobile(11px) getVwMobile(66px);
  font-size: getVwMobile(14px);

  @media (min-width: $lg) {
    padding: getVwDesktop(16px) getVwDesktop(56px);
    font-size: getVwDesktop(20px);
  }

  @media (min-width: $desktop) {
    padding: 16px 56px;
    font-size: 20px;
  }
}
